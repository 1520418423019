import React from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Card,
  Menu,
  MenuItem,
  Position,
  Spinner,
  Popover,
} from "@blueprintjs/core";

import { SectionTab } from "polotno/side-panel";
import FaFolder from "@meronex/icons/fa/FaFolder";
import { useProject } from "../project";
import * as api from "../api";

const DesignCard = observer(({ design, store, onDelete }) => {
  const [loading, setLoading] = React.useState(false);
  // const [previewURL, setPreviewURL] = React.useState(design.previewURL);

  // React.useEffect(() => {
  // const load = async () => {
  //   const url = await api.getPreview({ id: design.id });
  // };
  // setPreviewURL(design.previewURL);
  // load();
  // }, []);

  const handleSelect = async () => {
    setLoading(true);
    window.project.loadById(design.id);
    setLoading(false);
  };

  return (
    <Card
      style={{ margin: "3px", padding: "0px", position: "relative" }}
      interactive
      onClick={() => {
        handleSelect();
      }}
    >
      <img
        src={design.previewURL}
        style={{ width: "100%" }}
        alt={`Imagem de preview do design de id ${design.id}`}
      />
      <div
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          padding: "3px",
        }}
      >
        {design.name || "Untitled"}
      </div>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spinner />
        </div>
      )}
      <div
        style={{ position: "absolute", top: "5px", right: "5px" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Popover
          content={
            <Menu>
              <MenuItem
                icon="document-open"
                text="Open"
                onClick={() => {
                  handleSelect();
                }}
              />
              {/* <MenuItem
                icon="duplicate"
                text="Copy"
                onClick={async () => {
                  handleCopy();
                }}
              /> */}
              <MenuItem
                icon="trash"
                text="Delete"
                onClick={() => {
                  if (window.confirm("Tem certeza de que deseja excluí-lo?")) {
                    onDelete({ id: design.id });
                  }
                }}
              />
            </Menu>
          }
          position={Position.BOTTOM}
        >
          <Button icon="more" />
        </Popover>
      </div>
    </Card>
  );
});

export const MyDesignsPanel = observer(({ store }) => {
  const project = useProject();
  const [designsLoadings, setDesignsLoading] = React.useState(false);
  const [designs, setDesigns] = React.useState([]);

  const loadDesigns = async () => {
    setDesignsLoading(true);
    const list = await api.listDesigns();
    setDesigns(list);
    setDesignsLoading(false);
  };

  const handleProjectDelete = async ({ id }) => {
    const newDesignList = designs.filter((design) => design.id !== id);
    setDesigns(newDesignList);
    await api.deleteDesign({ id });
    const currentId = localStorage.getItem("braip-creative-last-design-id");
    console.log(currentId, id);
    if (String(currentId) === String(id)) {
      project.clear();
      if (newDesignList.length !== 0) {
        project.loadById(newDesignList[newDesignList.length - 1].id);
      } else {
        project.createNewDesign();
      }
    }
  };

  React.useEffect(() => {
    loadDesigns();
  }, [project.cloudEnabled, project.designsLength]);

  const half1 = [];
  const half2 = [];

  designs.forEach((design, index) => {
    if (index % 2 === 0) {
      half1.push(design);
    } else {
      half2.push(design);
    }
  });

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Button
        fill
        intent="primary"
        onClick={async () => {
          project.createNewDesign();
        }}
      >
        Criar novo design
      </Button>
      {!designsLoadings && !designs.length && (
        <div style={{ paddingTop: "20px", textAlign: "center", opacity: 0.6 }}>
          You have no saved designs yet...
        </div>
      )}
      {designsLoadings && (
        <div style={{ padding: "30px" }}>
          <Spinner />
        </div>
      )}
      <div
        style={{
          display: "flex",
          paddingTop: "5px",
          height: "100%",
          overflow: "auto",
        }}
      >
        <div style={{ width: "50%" }}>
          {half1.map((design) => (
            <DesignCard
              design={design}
              key={design.id}
              store={store}
              onDelete={handleProjectDelete}
            />
          ))}
        </div>
        <div style={{ width: "50%" }}>
          {half2.map((design) => (
            <DesignCard
              design={design}
              key={design.id}
              store={store}
              onDelete={handleProjectDelete}
            />
          ))}
        </div>
      </div>
    </div>
  );
});

// define the new custom section
export const MyDesignsSection = {
  name: "my-designs",
  Tab: (props) => (
    <SectionTab name="My Designs" {...props}>
      <FaFolder />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: MyDesignsPanel,
};
