import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "../hooks/useApi";

export const ProjectsList = () => {
  const { getProjects } = useApi();
  const [projects, setProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const projectsData = await getProjects();
        setProjects(projectsData);
        setFilteredProjects(projectsData);
      } catch (error) {
        console.error("Erro ao buscar projetos:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    setFilteredProjects(
      projects.filter((project) =>
        project.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, projects]);

  const handleAddProject = () => {
    navigate("/app"); // Redireciona para a página de criação de um novo projeto
  };

  const handleProjectClick = (id) => {
    localStorage.setItem("braip-creative-last-design-id", id);
    navigate("/app");
  };

  if (loading) {
    return (
      <div className="flex flex-col gap-4">
        <div className="w-full p-2 border rounded-md border-dark-200 bg-dark-150 text-light-0">
          <div className="animate-pulse">
            <div className="h-6 mb-4 rounded-md bg-dark-200"></div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
          {Array.from({ length: 6 }).map((_, index) => (
            <div key={index} className="p-4 rounded-md shadow-md bg-dark-250">
              <div className="h-48 mb-2 rounded-md bg-dark-200 animate-pulse"></div>
              <div className="h-6 mb-2 rounded-md bg-dark-200 animate-pulse"></div>
              <div className="h-4 rounded-md bg-dark-200 animate-pulse"></div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <input
          type="text"
          placeholder="Pesquisar em meus projetos..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="flex-1 p-2 border rounded-md border-dark-200 bg-dark-150 text-light-0"
        />
        <button
          onClick={handleAddProject}
          className="p-2 text-white rounded-md bg-primary-600 hover:bg-primary-700"
        >
          Criar Novo Projeto
        </button>
      </div>
      {filteredProjects.length > 0 ? (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
          {filteredProjects.map((project) => (
            <div
              key={project.id}
              className="p-4 rounded-md shadow-md cursor-pointer bg-dark-250"
              onClick={() => handleProjectClick(project.id)}
            >
              <img
                src={project.thumbnail}
                alt={project.title}
                className="object-cover w-full h-48 mb-2 rounded-md"
              />
              <h3 className="text-lg font-semibold text-light-0">
                {project.title}
              </h3>
            </div>
          ))}
        </div>
      ) : (
        <div className="p-4 text-center rounded-md bg-dark-250 text-light-500">
          Nenhum projeto encontrado.
        </div>
      )}
    </div>
  );
};
