import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { HelpCircle, LogOut, ChevronDown, Menu, X } from "lucide-react";

import { Commode2 } from "./icons/dark/commode-2";
import { Globe } from "./icons/dark/globe";
import { LayoutTopPanel3 } from "./icons/dark/layout-top-panel-3";
import { Brush } from "./icons/dark/brush";
import { H1 } from "./icons/dark/h1";

import LogoCreative from "../assets/img/logos/braip-creative-horizontal-branco.svg";
import UserProfile from "../assets/img/user-photo.png";
import { light } from "../assets/braip-theme/color";
import { DownloadButton } from "../topbar/download-button";
import { SaveButton } from "./save-button";
import { FileMenu } from "../topbar/file-menu";
import { useProject } from "../project";
import { useAuth } from "../hooks/useAuth";
import localforage from "localforage";

export const Navbar = ({ user, editor = false, store }) => {
  const [currentTrigger, setCurrentTrigger] = useState("");
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [name, setName] = useState(window.project.name || "Sem nome");
  const [photo, setPhoto] = useState("");

  const navigate = useNavigate();
  const { setAuth } = useAuth();

  const project = useProject();

  const pagesTrigger = useRef(null);
  const toolsTrigger = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest("#dropdownProfile") &&
        !event.target.closest("#dropdownProfileButton") &&
        openProfile
      ) {
        setOpenProfile(false);
      }

      if (
        !event.target.closest("#pagesTriggerId") &&
        pagesTrigger.current?.nextSibling?.dataset.state === "open" &&
        isOpenMenu
      ) {
        pagesTrigger.current?.nextSibling.click();
      }

      if (
        !event.target.closest("#toolsTriggerId") &&
        toolsTrigger.current?.nextSibling?.dataset.state === "open" &&
        isOpenMenu
      ) {
        toolsTrigger.current?.nextSibling.click();
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    console.log(isOpenMenu);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [openProfile, isOpenMenu]);

  const handleProfileDropdown = () => {
    setOpenProfile(!openProfile);
  };

  function updateName(value) {
    window.project.name = value;
    // window.project.requestSave();
  }

  function handleLogout() {
    setAuth({});
    localforage.removeItem("auth");
    navigate("/auth/sign-in");
  }

  useEffect(() => {
    updateName(name);
  }, [name]);
  useEffect(() => {
    setName(window.project.name);
  }, [window.project.name]);

  return (
    <NavigationMenu.Root
      value={currentTrigger}
      onValueChange={setCurrentTrigger}
      className={`bg-dark-150 items-center shadow-xl relative z-50 flex w-full justify-center border-b-1 border-light-1100 ${
        isOpenMenu
          ? "xl:!bg-none xl:top-auto bg-dark-150 max-xl:!fixed max-xl:top-0 max-xl:z-50 max-xl:!h-full"
          : ""
      } ${!editor ? "px-4" : ""}`}
    >
      <div
        className={`w-full h-full flex flex-wrap items-center transition-all xl:flex-nowrap
    ${
      !editor ? "xl:max-w-screen-xl xl:mx-8 xl:gap-4 2xl:max-w-screen-2xl" : ""
    }`}
      >
        <div className={`header-mobile ${isOpenMenu ? "py-4" : ""}`}>
          <Link to="/">
            <img className="h-[70px] p-2.5" src={LogoCreative} alt="Logo" />
          </Link>
          <button
            className="!text-light-0 block xl:hidden"
            onClick={() => setIsOpenMenu(!isOpenMenu)}
          >
            {!isOpenMenu ? (
              <Menu size={28} fill={light[0]} />
            ) : (
              <X size={24} fill={light[0]} />
            )}
          </button>
        </div>
        <div
          className={`wrapper-nav-elements ${
            isOpenMenu ? "height-calc-full" : "max-xl:!hidden"
          }`}
        >
          <NavigationMenu.List
            className={`w-full xl:h-20 m-0 flex items-center list-none p-2 xl:flex-row ${
              isOpenMenu ? "max-xl:flex-col max-xl:items-start" : ""
            }`}
          >
            <NavigationMenu.Item>
              {!editor && (
                <Link
                  className="!text-light-0/70 hover:!text-light-0 hover:bg-primary-100/5 select-none rounded-tiny p-4 text-sm font-medium leading-5 group flex gap-0.5 items-center justify-between outline-none hover:no-underline"
                  to="/"
                >
                  Home
                </Link>
              )}
            </NavigationMenu.Item>
            {!editor && (
              <NavigationMenu.Item>
                <Link
                  className="!text-light-0/70 hover:!text-light-0 hover:bg-primary-100/5 select-none rounded-tiny p-4 text-sm font-medium leading-5 group flex gap-0.5 items-center justify-between outline-none hover:no-underline"
                  to="/app"
                >
                  Editor de Designs
                </Link>
              </NavigationMenu.Item>
            )}
            {editor && (
              <>
                <FileMenu store={store} project={project} />
                <div
                  style={{
                    paddingLeft: "20px",
                    maxWidth: "200px",
                  }}
                >
                  <input
                    className="input-name-edit bg-light-0/5 p-[5px] 
                    outline-1 outline-primary-600/60 hover:outline-1 hover:outline-primary-600/60 focus:outline-1 focus:outline-primary-600/60 focus:border-0"
                    value={name}
                    placeholder="Titulo do design"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
              </>
            )}
          </NavigationMenu.List>
          <ul
            className={`wrapper-right-items ${isOpenMenu ? "max-xl:p-2" : ""}`}
          >
            {editor && (
              <li className="flex gap-6">
                <DownloadButton store={store} />
                <SaveButton store={store} />
              </li>
            )}
            <li
              className={`xl:p-4 items-center gap-2 flex ${
                isOpenMenu ? "px-4" : ""
              }`}
            >
              <div className="relative">
                <button
                  id="dropdownProfileButton"
                  className="flex gap-4 items-center text-sm font-semibold md:mr-0 text-light-0/70 hover:text-primary-100 cursor-pointer transition"
                  type="button"
                  onClick={handleProfileDropdown}
                >
                  <span className="sr-only">Abrir informações do usuário</span>
                  <div className="flex gap-3 items-center">
                    <div className="relative">
                      <img
                        className="w-8 h-8 rounded-full relative outline outline-2 outline-light-0/20"
                        src={photo || UserProfile}
                        alt="user avatar"
                      />
                    </div>
                    <span> Olá, {user["name"] || "Cliente"}! </span>
                  </div>

                  <div>
                    <ChevronDown size={20} />
                  </div>
                </button>
                <div
                  id="dropdownProfile"
                  className={`bg-dark-250 backdrop-blur-xl shadow-2xl z-50 top-12 absolute xl:right-0 hidden rounded-tiny w-56 p-2 divide-y divide-light-0/5 ${
                    openProfile && "!flex flex-col"
                  }`}
                >
                  <div className="py-2">
                    <a
                      href="https://ajuda.braip.com/"
                      target="_blank"
                      className="appearance-none w-full text-left px-4 py-3 text-sm flex items-center gap-2 hover:bg-white/10 !text-gray-200 hover:text-light-0 hover:no-underline"
                      rel="noreferrer"
                    >
                      <HelpCircle />
                      <span>Central de Ajuda</span>
                    </a>
                  </div>
                  <div className="py-2">
                    <button
                      className="appearance-none w-full text-left px-4 py-3 text-sm flex items-center gap-2 hover:bg-red-600 text-gray-200 hover:text-light-0"
                      onClick={handleLogout}
                    >
                      <LogOut size={24} />
                      <span>Deslogar</span>
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        className={`perspective-[2000px] absolute top-16 left-0 flex w-full z-[60] max-xl:!max-h-fill max-xl:!max-h-fill max-xl:!max-w-fill max-xl:m-6 ${
          isOpenMenu ? "max-xl:m-6 max-xl:top-[246px]" : "max-xl:hidden"
        } `}
      >
        <div className="relative w-full xl:mx-8 xl:max-w-screen-xl 2xl:max-w-screen-2xl left-1/2 -translate-x-1/2">
          <NavigationMenu.Viewport className="xl:ml-24 mt-2 data-[state=open]:animate-scaleIn data-[state=closed]:animate-scaleOut relative h-[var(--radix-navigation-menu-viewport-height)] w-full origin-[top_center] overflow-hidden rounded-tiny bg-dark-250/95 shadow-primary-600/90 backdrop-blur-2xl transition-[width,_height] duration-300 sm:w-[var(--radix-navigation-menu-viewport-width)]" />
        </div>
      </div>
    </NavigationMenu.Root>
  );
};

const NavigationMenuListItem = ({ title, to, href, icon, children }) => (
  <li className="flex flex-row gap-4">
    {to && (
      <Link
        to={to}
        className="border border-transparent hover:border-light-0/5 hover:bg-primary-100/5 flex gap-4 select-none rounded-tiny p-4 text-sm leading-none no-underline transition-colors hover:no-underline"
      >
        {icon === "pages" && <Commode2 size={36} className="min-w-[36px]" />}
        {icon === "templates" && (
          <LayoutTopPanel3 size={36} className="min-w-[36px]" />
        )}
        {icon === "domains" && <Globe size={36} className="min-w-[36px]" />}
        {icon === "creative" && <Brush size={36} className="min-w-[36px]" />}
        {icon === "headlines" && <H1 size={36} className="min-w-[36px]" />}
        <div className="space-y-1.5">
          <span className="text-purple-50 text-sm font-semibold">{title}</span>
          <p className="!text-light-0 text-sm leading-relaxed">{children}</p>
        </div>
      </Link>
    )}
    {href && (
      <a
        href={href}
        className="border border-transparent hover:border-light-0/5 hover:bg-primary-100/5 flex gap-4 select-none rounded-tiny p-4 text-sm leading-none no-underline transition-colors hover:no-underline"
      >
        {icon === "pages" && <Commode2 size={36} className="min-w-[36px]" />}
        {icon === "templates" && (
          <LayoutTopPanel3 size={36} className="min-w-[36px]" />
        )}
        {icon === "domains" && <Globe size={36} className="min-w-[36px]" />}
        {icon === "creative" && <Brush size={36} className="min-w-[36px]" />}
        {icon === "headlines" && <H1 size={36} className="min-w-[36px]" />}
        <div className="space-y-1.5">
          <span className="text-purple-50 text-sm font-semibold">{title}</span>
          <p className="!text-light-0 text-sm leading-relaxed">{children}</p>
        </div>
      </a>
    )}
  </li>
);
