import { useState } from "react";
import { redirect } from "react-router-dom";
import { useAxiosPrivate } from "./useAxiosPrivate";

export const useApi = () => {
  const [loading, setLoading] = useState({});
  const api = useAxiosPrivate();

  function updateLoading(key, value) {
    setLoading((prev) => ({ ...prev, [key]: value }));
  }

  async function getHomeData() {
    try {
      updateLoading("home-data", true);
      const response = await api.get("/home");
      return response.data;
    } catch (error) {
      console.log(error.response);
      if (error.response.status === 401) return redirect("/auth/sign-in");
      throw error;
    } finally {
      updateLoading("home-data", false);
    }
  }

  async function userData() {
    try {
      updateLoading("user-data", true);
      const response = await api.get("/me");
      return response.data;
    } catch (error) {
      console.log(error.response);
      redirect("/auth/sign-in");
      throw error;
    } finally {
      updateLoading("user-data", false);
    }
  }

  async function login({ email, password }) {
    try {
      updateLoading("login", true);
      const response = await api.post("/login", {
        email,
        password,
      });
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      updateLoading("login", false);
    }
  }

  async function register({ name, email, password, passwordConfirm }) {
    try {
      updateLoading("register", true);
      const response = await api.post("/register", {
        name,
        email,
        password,
        password_confirmation: passwordConfirm,
      });
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      updateLoading("register", false);
    }
  }

  async function sendRecoveryPassword({ email }) {
    try {
      updateLoading("forgot-password", true);
      const response = await api.post("/forgot-password", {
        email,
      });
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      updateLoading("forgot-password", false);
    }
  }

  async function updatePassword({ email, password, passwordConfirm, token }) {
    try {
      updateLoading("reset-password", true);
      const response = await api.post("/reset-password", {
        email,
        password,
        password_confirmation: passwordConfirm,
        token,
      });
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      updateLoading("reset-password", false);
    }
  }

  async function getProjects() {
    try {
      updateLoading("getProjects", true);
      const response = await api.get("/projects");
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      updateLoading("getProjects", false);
    }
  }

  async function listFiles() {
    try {
      updateLoading("list-files", true);
      const response = await api.get("/files");
      for (const file of response.data) {
        file.src = file.url;
        file.preview = file.thumbnail || file.url;
        file.type = file.type ? file.type : "image";
      }
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      updateLoading("list-files", false);
    }
  }

  async function uploadFile({ file }) {
    console.log("adsasd", file);
    try {
      updateLoading("upload-file", true);
      const response = await api.postForm("/files/upload", { file });
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      updateLoading("upload-file", false);
    }
  }

  async function deleteFile({ id }) {
    try {
      updateLoading("delete-file", true);
      return await api.delete(`/files/${id}`);
    } catch (error) {
      throw error;
    } finally {
      updateLoading("delete-file", false);
    }
  }

  return {
    loading,
    updateLoading,
    userData,
    login,
    register,
    getProjects,
    sendRecoveryPassword,
    updatePassword,
    listFiles,
    uploadFile,
    deleteFile,
    getHomeData,
  };
};
