import * as mobx from "mobx";
import { createContext, useContext } from "react";
import localforage from "localforage";

import * as api from "./api";

window.localforage = localforage;

export const ProjectContext = createContext({});

export const useProject = () => useContext(ProjectContext);

const getFromStorage = (key) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    return null;
  }
};

const setToStorage = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {}
};

class Project {
  id = "";
  template_id = "";
  name = "";
  user = {};
  skipSaving = false;
  cloudEnabled = false;
  status = "saved"; // or 'has-changes' or 'saving' or 'loading'
  language = getFromStorage("polotno-language") || navigator.language || "en";
  designsLength = 0;

  constructor({ store }) {
    mobx.makeAutoObservable(this);
    this.store = store;

    // store.on("change", () => {
    //   this.requestSave();
    // });

    setInterval(() => {
      mobx.runInAction(() => {
        this.cloudEnabled = window.puter?.auth?.isSignedIn();
      });
    }, 100);
  }

  setLanguage(lang) {
    this.language = lang;
    setToStorage("polotno-language", lang);
  }

  requestSave(mode) {
    const id = localStorage.getItem("braip-creative-last-design-id");
    const template_id = localStorage.getItem("braip-creative-template-saved");
    this.status = "has-changes";
    if (this.saveTimeout) {
      return;
    }
    this.id = id;
    this.template_id = template_id;
    if (id) {
      // this.saveTimeout = setTimeout(() => {
      // this.saveTimeout = null;
      // skip autosave if no project opened
      this.save({ mode });
      // }, 500);
    }
  }

  async firstLoad() {
    const lastDesignId = localStorage.getItem("braip-creative-last-design-id");
    if (!lastDesignId) return this.createNewDesign();
    await this.loadById(lastDesignId);
  }

  async loadById(id) {
    this.id = id;
    localStorage.setItem("braip-creative-last-design-id", id);
    this.status = "loading";
    try {
      const { storeJSON, name, template_id } = await api.loadById({
        id,
      });
      if (storeJSON) {
        this.store.loadJSON(storeJSON);
      }
      this.name = name;
      if (template_id) {
        this.template_id = template_id;
      }
    } catch (e) {
      console.error(e);
      localStorage.removeItem("braip-creative-last-design-id");
      this.createNewDesign();
    }
    this.status = "saved";
  }

  async loadByTemplateId({ template_id, designId }) {
    this.id = designId;
    localStorage.setItem("braip-creative-last-design-id", designId);
    this.status = "loading";
    try {
      const { storeJSON, name } = await api.loadByTemplateId({
        template_id,
      });
      if (storeJSON) {
        this.store.loadJSON(storeJSON);
      }
      this.name = name;
    } catch (e) {
      console.error(e);
      alert("não pode carregar o template");
      // localStorage.removeItem("braip-creative-last-design-id");
      // this.createNewDesign();
    }
    this.status = "saved";
  }

  updateUrlWithProjectId() {
    if (!this.id || this.id === "local") {
      window.history.replaceState({}, null, `/`);
      return;
    }
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    params.set("id", this.id);
    window.history.replaceState({}, null, `/design/${this.id}`);
  }

  async canvasToBlob({ maxWidth = 200 }) {
    const canvas = await this.store._toCanvas({
      pixelRatio: maxWidth / this.store.activePage?.computedWidth,
      pageId: this.store.activePage?.id,
    });
    const blob = await new Promise((resolve) => {
      canvas.toBlob(resolve, "image/jpeg", 0.9);
    });
    return blob;
  }

  async save({ createNew, mode }) {
    this.status = "saving";
    const storeJSON = this.store.toJSON();
    const maxWidth = 200;

    if (!window.location.pathname.startsWith("/app")) {
      this.status = "not-saved";
      return;
    }

    const preview = await this.canvasToBlob({ maxWidth });
    // const canvas = await this.store._toCanvas({
    //   pixelRatio: maxWidth / this.store.activePage?.computedWidth,
    //   pageId: this.store.activePage?.id,
    // });
    // const blob = await new Promise((resolve) => {
    //   canvas.toBlob(resolve, "image/jpeg", 0.9);
    // });
    try {
      const res = await api.saveDesign({
        storeJSON,
        preview,
        id: this.id,
        template_id: this.template_id,
        name: this.name,
        createNew,
        mode,
      });
      if (res.status === "saved") {
        if (res.type === "template") {
          this.template_id = res.id;
          localStorage.setItem("braip-creative-template-saved", res.id);
          return;
        }
        this.id = res.id;
        localStorage.setItem("braip-creative-last-design-id", res.id);
      }
    } catch (e) {
      console.error(e);
    }
    this.status = "saved";
  }

  async duplicate() {
    this.id = "";
    this.save();
  }

  async clear() {
    this.store.clear();
    this.store.addPage();
    localStorage.removeItem("braip-creative-last-design-id");
  }

  async createNewDesign() {
    this.clear();
    this.name = "Untitled Design";
    this.id = "";
    this.store.openSidePanel("photos");
    console.log("saving");
    await this.save({ createNew: true });
    console.log("saving done");
  }

  async signIn() {
    await window.puter.auth.signIn();
    this.designsLength = await api.backupFromLocalToCloud();
  }
}

export const createProject = (...args) => new Project(...args);
export default createProject;
